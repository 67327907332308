import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import Cookies from 'universal-cookie';
import useAuth from '../Hook/useAuth';
import MessageItem from './MessageItem';

// const [indexMessage, setindexMessage] = useState(0)


const Messages = () => {
  const { auth , logout , setAuth } = useAuth()
const navigate = useHistory()
const cookies = new Cookies();
const [data, setData] = useState(null);
const fetchData = async () => {
  try {
    const response = await axios.get("function_patient/alerts", {
      headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true }
    });
    if (response.status === 200) {
      // console.log("Fetched Data:", response.data); // הדפסת המידע
      setData( response.data);
    }
  } catch (error) {
    console.error("Error fetching data:", error); // הדפסת השגיאה
  }
};


const handleMarkAsReadAll = async() => {


  // אופציונלי: שליחת עדכון לשרת
  const r= await axios.get(`function_patient/mark_alerts_read`,{
    headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true }
  }).catch((error) => {
    console.error("Failed to mark as read:", error);
  });
  if(r.status === 200)
  {fetchData()}
};

if(auth)
  {
    if(cookies.get('isProfilePassed') == null)
    {  
      cookies.set('isProfilePassed' , true ,  { path: '/'  , maxAge: 31536000} ,   {httpOnly: true}, {SameSite:"None"})
    }
  }
  else{
       navigate.replace('/Login')
  }

  const unreadCount = data? data.filter((n) => !n.isRead).length :0;


useEffect(()=>{ fetchData()},[])  
// }
// catch(err){
//   console.log(err)
// }
  return (
    <div className='w-full h-[80%]  overflow-y-auto flex flex-col items-center ' style={{ background: "linear-gradient(0deg, #F0F0F0 0.06%, #F4F4F4 82.66%, #F6F6F6 100%)" ,direction:"rtl" }}>
      <div className='w-full flex justify-between px-2 items-center'>
        
        <h1 className='font-semibold underline text-2xl'>
          הודעות
        </h1>
        <span onClick={handleMarkAsReadAll} className="cursor-pointer" style={{fontWeight: unreadCount ? "bold" : "",}}>{`סמן כנקראו (${unreadCount})`}</span>
      </div>
      
     {data?.map((message)=>{return <MessageItem data={message} fetchData={fetchData}></MessageItem>})}


     
    </div>



  )
}

export default Messages