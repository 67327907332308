import React, { useEffect, useState } from "react";
import "./Notification.css"; // ניתן להוסיף עיצוב בקובץ CSS
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom";
import useAuth from "../../Hook/useAuth";
import Cookies from "universal-cookie";
import axios from "axios";
const Notification = () => {
  const [isNote, setIsNote]=useState(Boolean)
  const { auth , logout , setAuth } = useAuth()
  const navigate = useHistory()
  const cookies = new Cookies();
  const [data, setData] = useState(null);
 
  
  
  

    const fetchData = async () => {
      try {
        const response = await axios.get("function_patient/alerts", {
          headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true }
        });
        if (response?.status === 200) {
          // console.log("Fetched Data:", response.data); // הדפסת המידע
          setData( response?.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error); // הדפסת השגיאה
      }
    };
  // useEffect(()=>{ fetchData()},[])  
  useEffect(()=>{ setTimeout(() => {
    fetchData()
  }, 2000)},[])  
 
      // console.log(data?data:null)
      // סינון הודעות שלא נקראו
      const unreadCount = data?.filter((n) => !n?.isRead).length;
    

    
      return (
        <div className="notification-wrapper ml-2">
          <Link to="/Messages" className="notification-icon" >
            <span className="icon">📩</span>
            {unreadCount > 0 && <span className="badge">{unreadCount}</span>}
          </Link>
        </div>
      );
}

export default Notification