import React from 'react'
import stage1_full_Badge from "../../Images/Dashboard/Tags/stage1-full_Badge.svg"
import stage2_full_Badge from "../../Images/Dashboard/Tags/stage2-full_Badge.svg"
import stage3_full_Badge from "../../Images/Dashboard/Tags/stage3-full_Badge.svg"
import stage4_full_Badge from "../../Images/Dashboard/Tags/stage4-full_Badge.svg"
import stage5_full_Badge from "../../Images/Dashboard/Tags/stage5-full_Badge.svg"
import stage1_step_Badge from "../../Images/Dashboard/Tags/stage1-step_Badge.svg"
import stage2_step_Badge from "../../Images/Dashboard/Tags/stage2-step_Badge.svg"
import stage3_step_Badge from "../../Images/Dashboard/Tags/stage3-step_Badge.svg"
import stage4_step_Badge from "../../Images/Dashboard/Tags/stage4-step_Badge.svg"
import stage5_step_Badge from "../../Images/Dashboard/Tags/stage5-step_Badge.svg"
import stagenull_step_Badge from "../../Images/Dashboard/Tags/stage-step_Badge_null.svg"
import stagenull_full_Badge from "../../Images/Dashboard/Tags/stage-full_Badge_null.svg"

import underline from "../../Images/Dashboard/title-underline.svg"
import { useQuery } from "react-query";
import axios from 'axios'
import useAuth from '../../Hook/useAuth';
import  {useHistory , useLocation }  from "react-router-dom";
// import stage1_full_Badge from "../../Images/Dashboard/Tags/stage1-full_Badge.svg"






const Myway = () => {
    const arr={0:stage1_full_Badge,
        1:stage2_full_Badge,
        2:stage3_full_Badge,
        3:stage4_full_Badge,
        4:stage5_full_Badge
    }
    const arrsmall={0:stage1_step_Badge,
        1:stage2_step_Badge,
        2:stage3_step_Badge,
        3:stage4_step_Badge,
        4:stage5_step_Badge
    }


    const { auth , logout , setAuth } = useAuth()
    const navigate = useHistory()

    const { isLoading, isError, data, error, refetch } = useQuery(["album"], () =>
    axios.get(`function_patient/album` ,
    { 
    headers: { 'Content-Type': 'application/json'}
    } ,
    {'Access-Control-Allow-Credentials': true}) )
  
  
  if (error) 
  {
    if(!auth)
    {
      navigate.replace('/Login')
    }
    return "An error has occurred: " + error.message;
  } 
//    console.log(data?.data)





  return (
    

    // <div className='flex flex-col p-4 divide-y-2 divide-[rgb(165,112,121)]'>
    <div className='flex flex-col p-2 bg-white rounded-2xl rounded-tl-none  '>
        {data?.data?.map((stage,index)=>{return(
                
        
        <div>
        <div className='flex flex-row-reverse py-4  '>
            <div className=' flex flex-col ml-2 w-[20vw] '>
                <img className='w-[20vw]' src={stage.status=="mark"?arr[index]:stagenull_full_Badge} alt="4" />
                <p className='text-center font-medium' style={{fontSize:"4vw"}}>{stage.nameOfLevel}</p>
            </div>
            
            <div className='w-[70vw]  grid grid-cols-3 gap-4    ' style={{direction: "rtl"}}>
                {stage?.albumTechs?.map((tech)=>{return(
               <div className=' flex flex-col  items-center'>
               <img className='w-[10vw]'src={tech.status=="mark"?arrsmall[index]:stagenull_step_Badge} alt="1" />
               <p className='text-center' style={{fontSize:"3vw"}}>{tech.nameTech}</p>
               </div>
                )})}
                {/* <img className='w-[15vw] mx-2'src={stage1_step_Badge} alt="3" /> */}
            </div>
        </div>
        {index!="4"?<img src={underline} className='w-[100vw] h-[4vw]'></img>:""}
        </div>
        
       )})}
       
        {/* <img src={underline} className='w-[100vw] h-4'></img>



        <div className='flex flex-row-reverse py-4  '>
            <div className='w-[20vw] flex ml-2'>
                <img src={stage1_full_Badge} alt="4" />
            </div>
            
            <div className='  grid grid-cols-3 gap-4 '>
                <img className='w-[15vw] 'src={stage1_step_Badge} alt="1" />
                <img className='w-[15vw] 'src={stage1_step_Badge} alt="1" />
                <img className='w-[15vw] 'src={stage1_step_Badge} alt="2" />
                
            </div>
        </div>
       
       
        <img src={underline} className='w-[100vw] h-4'></img>




        <div className='flex flex-row-reverse py-4  '>
            <div className='w-[20vw] flex ml-2'>
                <img src={stage1_full_Badge} alt="4" />
            </div>
            
            <div className='  grid grid-cols-3 gap-4 '>
                <img className='w-[15vw] 'src={stage1_step_Badge} alt="1" />
                <img className='w-[15vw] 'src={stage1_step_Badge} alt="1" />
                <img className='w-[15vw] 'src={stage1_step_Badge} alt="2" /> */}
                
                {/* <img className='w-[15vw] mx-2'src={stage1_step_Badge} alt="3" /> */}
            {/* </div>
        </div>
       
       
        <img src={underline} className='w-[100vw] h-4'></img> */}

       

       
    </div>
  )
}

export default Myway