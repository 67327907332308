import React, { useState } from 'react'
import Video from './Video'
import InfoPop from "./InfoPop";
//כאן צריך לשנות אם עוז ירצה
const Trainings_List = (props) => {
  const [show,setShow]=useState(false)
  // {console.log(props)}
  return (
    <div className='my-2 mr-2 p-2 bg-white rounded-3xl'>
    <div id="main-title" className='flex justify-end font-normal font-Hebbo text-2xl p-3  text-black text-right '>
    {props.levelId=="5"&&<div className='text-2xl mr-1 w-[5%]' style={{display: "flex",
  // justifyContent: "center",
  // alignItems: "center",
  // width: "30px", /* גודל הכפתור */
  // height: "30px",
  // backgroundColor: "#f3f4f6", /* רקע עדין */
  // borderRadius: "50%", /* עיגול מושלם */
  // border: "1px solid #ddd", /* מסגרת */
  // boxshadow: "0 2px 4px rgba(0, 0, 0, 0.1)", /* צל עדין */
  // color: "#555", /* צבע האייקון */
  // fontSize: "16px",
  // cursor: "pointer",
  // transition: "all 0.3s ease"
}} onClick={()=>{
    {/* {props.levelId=="5"&&<div className='shadow-[0 2px 4px rgba(0, 0, 0, 0.1)] text-[16px] cursor-pointer transition-all 0.3s ease-linear flex justify-center items-center rounded-full bg-[#f3f4f6] border border-[#ddd] mr-1 w-[5%]' onClick={()=>{ */}
      setShow(true)
      // console.log(props.item.main_title)
      }}>{" \u24d8"}</div>}

    {show&& <InfoPop main_title={props.item.main_title} setShow={setShow}></InfoPop>}
   <span className='w-[95%]'>{props.item.main_title}</span>
        
       
        

    </div >
    <div className='flex flex-row-reverse overflow-x-auto'  bg-midnight="true">
            {/* <Video Video={props.Video} time="1:30" discrption="נשימה עם דיבור עצמי חיובי"/> */}
            {props.item.info_videos.map((video)=>( <Video time={video.total_train_time} actionId={video.actionId} discrption={video.name} levelId={props.levelId} urlImage={video.urlImage}/>))}
           
            {/* <Video time="1:30"  discrption="התמודדות עם פחדים או רגשות שליליים עם מוסיקה"/>
            <Video time="1:30"  discrption="sdadcc"/>
            <Video time="1:30"  discrption="sdadcc"/>
            <Video time="1:30"  discrption="sdadcc"/>
            <Video time="1:30"  discrption="sdadcc"/> */}
    </div>
    </div>
  )
}

export default Trainings_List