// import React from 'react'
// import bubble from "../Images/bubble.svg"
// import { useState } from 'react';
// import ScaleH from '../Components/ScaleH';
// import ScaleV from '../Components/ScaleV';
// import Numbers from '../Components/Numbers';
// import axios from 'axios'
// import { useEffect } from 'react';

// const Dashboard = ({step  , setCurrentPage  , execiserIdBuild}) => {
 
  
//   const [heightscreen ,SetheightScreen] = useState()
//   const [col1 ,setcol1] = useState(-1)
//   const [col2 ,setcol2] = useState(-1)
//   const [col3 ,setcol3] = useState(-1)
//   const [col4 ,setcol4] = useState(-1)

//   useEffect ( () => 
//   {
      
//           console.log(col1,col2,col3,col4)      
//   } , [col1,col2,col3])
//   const onSubmit = async(e) =>
//   {
//     if(step === "q1")
//     {
//       e.preventDefault()
//       await axios.post('function_patient/build_questionnaire_primer' , 
//       JSON.stringify({exercise_id: execiserIdBuild , suds: 5 ,
//                       vas:5 ,
//                       fatigue:5 ,
//                       well_bing:5}) , 
//       { 
//         headers: { 'Content-Type': 'application/json'}
//       } ,
//       {'Access-Control-Allow-Credentials': true})
//         .then((res) => {
//         if(res?.status === 200)
//          {
//             //  if(!res?.data === "Exercise didnt found!")
//             //  {
//             //   console.log(res?.data)
//             //   // onNext()
//             //   // setCurrentPage('video')
//             //  }
//             //  else{
//             //    console.log(res?.data)
//             //    //navigate back..
//             //  } 
//             console.log(res?.data)
//             setCurrentPage('video')
//          }
//          else{
//             if(res.response?.status === 401)
//              {
//                //logoutFun()
//              }
//          }
//       })
//         .catch( (err) => 
//             {
              
//               // if(err.response?.status === 401)
//               // {
//               //   logoutFun()
//               // }
//             })
//     }
//     else{
//       if(step === "q2")
//       {
//         e.preventDefault()
//         await axios.post('function_patient/build_questionnaire_last' , 
//         JSON.stringify({exercise_id: execiserIdBuild ,
//                         date_start_quest_second : "04/05/2023 17:24:05" ,  
//                         suds: 5 ,
//                         vas:5 ,
//                         fatigue:5 ,
//                         well_bing:5}) , 
//         { 
//           headers: { 'Content-Type': 'application/json'}
//         } ,
//         {'Access-Control-Allow-Credentials': true})
//           .then((res) => {
//           if(res?.status === 200)
//            {
//               //  if(!res?.data === "Exercise didnt found!")
//               //  {
//               //   console.log(res?.data)
//               //   onNext()
//               //  }
//               //  else{
//               //    console.log(res?.data)
//               //    //navigate back..
//               //  } 
//               console.log(res?.data)
//               setCurrentPage('Finish')
                
//            }
//            else{
//               if(res.response?.status === 500)
//                {
//                 console.log(res?.message)
//                  //logoutFun()
//                }
//            }
//         })
//           .catch( (err) => 
//               {
                
//                 // if(err.response?.status === 401)
//                 // {
//                 //   logoutFun()
//                 // }
//               })
//       }
//     }
   

//   }
//   useEffect(() => {
//     SetheightScreen(window.innerHeight)
//     console.log(heightscreen)
// }, [window.innerHeight])

// // console.log(window.innerHeight>670?"42vh":"32vh")
// // SetheightScreen(window.innerHeight>670?"42vh":"32vh")
//   return (
//     <div className='  h-full flex flex-col  items-center bg-bermuda p-2 '> 
  
//     {/* <div className=' w-full h-full ' style={{ backgroundImage: `url(${bubble})` }}> */}
//     {/* <div className=' w-full h-full '>
//       <img src={bubble} alt="" className='mx-6 pt-5'/>
//     <p className=' w-full  flex justify-center'>sda</p></div>
//     */}
//     {/* <div className='   relative mx-6 pt-5 '> */}
//     <div className='w-full sm:w-[400px]'>
//  
//     {/* <p className=' absolute  flex justify-center items-center pb-10 '>sda</p>
//       <img src={bubble} alt="" className=''/> */}
//     {/* <p className=' absolute w-full  flex justify-center '>sda</p> */}
//      </div>


//      {/* <div className={`min-h-[650px]:h-[0vh] h-[45vh] max-[380px]:h-[32vh] max-[320px]:h-[35vh] rounded-3xl bg-white m-4 flex flex-row  `}  > */}
//      <div className={window.innerHeight >670?"rounded-3xl bg-white m-4 flex flex-row h-[44vh]":"rounded-3xl bg-white m-4 flex flex-row h-[32vh]"}  >
//     <div className=' flex justify-center items-center flex-col p-4 w-1/3'>
//     <text className='font-Hebbo font-bold text-center pr-8 '>כאב</text>
    
//     <div className='flex flex-row  h-full w-full  justify-center'>
//     <ScaleV setcol={setcol1} col={col1}/>
//     <Numbers/>
//     </div>
//     </div>
    
    
    
//     <div className=' flex justify-center flex-col w-1/3 p-4'>
//     <text className='font-Hebbo font-bold text-center pr-8'>מתח</text>
//     <div className='flex flex-row  h-full w-full justify-center'>
//     <ScaleV setcol={setcol2} col={col2}/>
//     <Numbers/>
//     </div>
//     </div>


//     <div className='h-full flex justify-center  flex-col p-4 w-1/3'>
//     <text className='font-Hebbo font-bold text-center pr-8'>עייפות</text>
//    <div className='flex flex-row  h-full w-full justify-center '>
//     <ScaleV setcol={setcol3} col={col3} className="ml-8"/>
//     <Numbers />
//     </div>
//     </div>
    
  
    
//     </div>

    
//     <div className='w-[80%] sm:w-[400px]  rounded-lg bg-white  p-2 flex justify-center flex-col items-center  ' >
//     <text className='font-Hebbo font-bold mt-2'>תחושת רווחה</text>
//     <ScaleH setcol={setcol4} col={col4}/> 
//     </div>
//     <div className={step=="q2"?'w-[80%] sm:w-[400px]  px-4  py-2 flex justify-end':'w-[80%] sm:w-[400px]  px-4  py-2 flex justify-start'}>
//             {step=="q2"?<button className=' rounded-2xl  p-4 font-Hebbo font-normal   text-white' style={{background:"linear-gradient(90deg, #E72144 0%, #76549E 99.94%)"}}onClick={onSubmit} >סיימתי</button>
//             :
//             (col1>-1&&col2>-1&&col3>-1&&col4>-1)?<button className=' rounded-2xl  p-4  font-Hebbo font-normal text-white flex justify-end 'style={{background:"#394351"}} onClick={onSubmit}>המשך</button>:<button className=' rounded-lg  p-4 disabled:bg-luck '>המשך</button>}


            
//         </div>
  
//   </div>
//   )
// }

// export default Dashboard
// import ReactPlayer from "react-player";
import {React ,useState, StrictMode}from 'react'
// import reactPlayer from 'react-player'
// import stage1_videoCover from "../Images/Videos/stage1_videoCover.svg" 
import Gauge from "../Components/Dashboard/Gauge";
import Myway from "../Components/Dashboard/Myway";
import Dashbord_Tutorilias from '../Components/Dashboard/Dashbord_Tutorilias';
import under_constructions from "../Images/Dashboard/under-constructions.svg"




const Dashboard = () => {
  const [tab ,setTab] = useState(3)
  const bg_color="linear-gradient(0deg, #F0F0F0 0.06%, #F4F4F4 82.66%, #F6F6F6 100%)"
  return (
    // <div className='h-screen flex flex-col p-4 justify-center items-center' style={{background: "linear-gradient(0deg, #F0F0F0 0.06%, #F4F4F4 82.66%, #F6F6F6 100%)"}}>
    //   <span className="-right">....בבניה, נתראה בקרוב</span>
    //       <img src={under_constructions} className="w-[80%]"></img>


    // </div>

    
    <div className='h-[80%] overflow-y-auto flex flex-col p-4 '  style={{background:bg_color,}}>
     {/* <div className='h-screen overflow-y-auto bg-slate-700 flex-col p-4'> */}
         <div>
        {/* <div className="w-1/3 flex bg-transparent relative">
          <div className="w-[80%] bg-white">sds</div> */}
          {/* <div className="w-[20%] bg-black " style={{clipPath:"polygon(0% 0% ,20% 20%,20% 30%, 40% 40%, 50% 50%,100% 100%, 0% 100%"}}>2</div> */}
          {/* <div className="w-[20%] rounded-bl-xl bg-transparent absolute z-10 right-[10px] " >
   asd       </div>
        </div> */}

         <div className="flex flex-row bg-transparent w-full justify-between ">
             {/* <button className="px-4 p-2  rounded-br-2xl bg-transparent" style={{background:bg_color}}> הדרך שלי</button> */}
             <button className={tab ==1? "  px-4 pb-2 mr-4 rounded-t-2xl relative bg-white font-bold":"px-4 p-2 z-[3]  mr-4 rounded-2xl font-medium text-gray-500 bg-[#F1ECEE] mb-2" } style={{fontSize:"5vw"}} onClick={()=>setTab(1)} > הדרך שלי
              {tab==1&&<div className='w-[30%] h-[50%] bg-white absolute bottom-0 left-full z-[2]'> 
             <div className='w-full h-full bg-[#F6F6F6] rounded-bl-3xl'> </div> 
             </div> }
             </button>
             <button  className={tab ==2?"px-4 relative pb-2 rounded-t-2xl bg-white font-bold":"px-4 p-2 z-[3] rounded-2xl font-medium text-gray-500 bg-[#F1ECEE] mb-2 "} style={{fontSize:"5vw"}} onClick={()=>setTab(2)}> מדדים
             {tab==2&&<div className='w-[30%] h-[50%] bg-white absolute bottom-0 right-full z-[2]'> 
             <div className='w-full h-full bg-[#F6F6F6] rounded-br-3xl'> </div></div>}
             {tab==2&&<div className='w-[30%] h-[50%] bg-white absolute bottom-0 left-full z-[2]'> 
             <div className='w-full h-full bg-[#F6F6F6] rounded-bl-3xl'> </div> 
             </div> }
             </button>
             
             
             <button className={tab ==3?"px-4 pb-2  ml-4 rounded-t-2xl relative bg-white font-bold":"px-4 p-2 z-[3]  ml-4 rounded-2xl font-medium text-gray-500 bg-[#F1ECEE] mb-2 "} style={{fontSize:"5vw"}} onClick={()=>setTab(3)}> תירגולים
             {tab==3&&<div className='w-[30%] h-[50%] bg-white absolute bottom-0 right-full z-[2]'> 
             <div className='w-full h-full bg-[#F6F6F6] rounded-br-3xl'> </div></div>}
             </button>
         </div>
        
        {tab==2?<Gauge/>:tab==1?<Myway></Myway>:<Dashbord_Tutorilias></Dashbord_Tutorilias>} 
        {/* <Control    
          onPlPause={pyPauseHandler}
            playing={pying}
           onRewind={rewindHandler}
           onForward ={handleFastFoward }
         />  */}
     </div>
   </div>
    
  )
}

export default Dashboard