import axios from 'axios';
import React, { useState } from 'react'

const MessageItem = ({ data, fetchData }) => {
    const[show,setShow]=useState(false)
    //   return (
    //     <div className=' h-24 m-2 p-2 flex  border font-bold gap-x-3 border-blue-300 bg-slate-200 rounded-md  '>
    //         <div className='w-[20%] flex flex-col '>
    //           {/* <div>
    //             {data?.date}
    //           </div> */}
    //           <div>
    //             א
    //           </div>
    //           <div>
    //             10:00
    //           </div>

    //         </div>
    //         <div className='flex flex-col'>
    //         <text className='leading-none'>
    //              {data?.message}
    //         </text>
    //         <span className='text-sm text-gray-700'>{!data?.isRead?"לא נקרא":" נקרא"}</span>
    //         </div>
    //       </div>
    //   )

    
    const handleMarkAsRead = async(alertId) => {


    // אופציונלי: שליחת עדכון לשרת
    const r= await axios.put(`function_patient/alert_change_read/${alertId}`,{
      headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true }
    }).catch((error) => {
      console.error("Failed to mark as read:", error);
    });
    if(r.status === 200)
    {fetchData()}
  };

    const formatDate = (dateString) => {
        const options = { year: "numeric", month: "long", day: "numeric", hour: "2-digit", minute: "2-digit" };
        return new Date(dateString).toLocaleDateString("he-IL", options);
    };

    return (
        <div
            style={{
                border: "1px solid #ccc",
                borderRadius: "8px",
                padding: "10px",
                margin: "6px 0",
                backgroundColor: data?.isRead ? "#f5f5f5" : "#e6f7ff",
                fontWeight: data?.isRead ? "" : "bold",
                boxShadow: data?.isRead ? "none" : "0 2px 5px rgba(0, 0, 0, 0.1)",
            }}
            className='cursor-pointer w-[95%]'
            onClick={() => {
                if (!data?.isRead) {
                    handleMarkAsRead(data?.alertId)
                }
                setShow((pershow)=>{return(!pershow)})
            }}
        >
            
<p className={`${!show?"line-clamp-3":""}`}>
          {  (data?.message).split("\\n").map((line, index) => (
    <p key={index} style={{ margin: 0 }}>
      {line.replaceAll("\\","")}
    </p>
    
  ))
}
</p>


            {/* <p style={{ fontWeight: "bold", margin: "0 0 5px"  }} className="whitespace-pre-line ">{data?.message.replace('\\n',"\n ")}</p> */}
            
            {/* <p className= {`whitespace-pre-line text-right font-Hebbo font-normal text-lg px-3 ${spamshow&&!show?"line-clamp-3" : ""} `} style={{direction: 'rtl'}}>
                
                {description}
                
        </p> */}
            
            
            
            
            
            
            
            <p style={{ margin: "0", fontSize: "0.9em", color: "#555" }}>
                תאריך: {formatDate(data?.date)}
            </p>
            {!data?.isRead && <span style={{ color: "red", fontWeight: "bold" }}>חדש</span>}
        </div>
    );

}

export default MessageItem