import React, { useEffect, useState } from 'react'
import useAuth from '../Hook/useAuth'
import sun from '../Images/Dashboard/toggle_sun.svg'
import moon from '../Images/Dashboard/toggle_moon.svg'
import  {useHistory , useLocation }  from "react-router-dom";

import Play_Circle from "../Images/Profile/Play_Circle_.svg"
// import woman from "../Images/Profile/woman.svg"
import woman from "../Images/Profile/Female2.png"
import notification from "../Images/Profile/Notification.png"
// import man from "../Images/Profile/male.svg"
import man from "../Images/Profile/male2.png"
import Cookies from "universal-cookie"
import axios from 'axios'
import { useQuery } from "react-query";

import infosvg from "../Images/Dashboard/info.svg"
import { Link } from 'react-router-dom'
// import Notification from '../Components/UI/Notification';

const Profile = () => {
 const [nickName, setnickName]= useState("")
 const [isPlay, setisPlay]= useState(false)
  const [morning, setMorning]=useState("")
  const [evening, setEvening]=useState("")
  const [gender, setGender]=useState("")
  const [isNote, setIsNote]=useState(Boolean)
  const { auth , logout , setAuth } = useAuth()
  const navigate = useHistory()
  const cookies = new Cookies();


  
  // const [moring, setMorning]=useState("")

  if(auth)
  {
    if(cookies.get('isProfilePassed') == null)
    {  
      cookies.set('isProfilePassed' , true ,  { path: '/'  , maxAge: 31536000} ,   {httpOnly: true}, {SameSite:"None"})
    }
  }
  else{
       navigate.replace('/Login')
  }


  const { isLoading, isError, data, error, refetch } = useQuery(["profile"], () =>
  axios.get(`function_patient/profileShow` ,
  { 
  headers: { 'Content-Type': 'application/json'}
  } ,
  {'Access-Control-Allow-Credentials': true})   , 
  
  
  
  )
  // {
  //   onSuccess: (res) => {
  //     const result = {
  //       data: res?.data,
  //     };  
  //   setnickName(result.data?.nickName)
  //   setMorning(result.data?.morningPreferTime)
  //   setEvening(result.data?.eveningPreferTime)
  //   setGender(result.data?.gender)
  //   },
  //   onError: (err) => {
  //   },
  // }
  useEffect(()=>{
        // console.log(data)
       setnickName(data?.data?.nickName)
       setMorning(data?.data?.morningPreferTime)
       setEvening(data?.data?.eveningPreferTime)
      setGender(data?.data?.gender)
      setIsNote(data?.data?.isNote)

  },[data])

  if (error) 
  {
    if(!auth)
    {
      navigate.replace('/Login')
    }
    return "An error has occurred: " + error.message;
  } 
 



  const submit = async(e) =>{

    e.preventDefault()
    await axios.post('function_patient/profileUpdate' , 
    JSON.stringify({nickName: nickName , morningPreferTime: morning ,eveningPreferTime: evening ,
      gender: gender,isNote:isNote}) , 
    { 
      headers: { 'Content-Type': 'application/json'}
    } ,
    {'Access-Control-Allow-Credentials': true})
      .then((res) => {
      if(res?.status === 200)
       {
        
        // console.log(res?.data)
        navigate.replace("/Home")
       }
       else{
          if(res.response?.status === 500)
           {
            // console.log(res?.data)
             //logoutFun()
           }
       }
    })
      .catch( (err) => 
          {
            
          })

  }



  return (
    
    // <div className='w-full flex justify-center center'style={{background: "linear-gradient(0deg, #F0F0F0 0.06%, #F4F4F4 82.66%, #F6F6F6 100%)",height:"calc(100% - 200px)"}}>height: "-webkit-fill-available"
    <div className='w-full h-[80%]  overflow-y-auto flex justify-center center 'style={{background: "linear-gradient(0deg, #F0F0F0 0.06%, #F4F4F4 82.66%, #F6F6F6 100%)"}}>
       {/* <div className='w-[80%] max-h-[100%]  bg-white shadow-2xl border-b-bermuda rounded-lg  p-3'> */}
       <div className='w-[100%] max-w-2xl h-full p-3 flex '>
        {/* <form className="mt-2" onSubmit={handleSubmit}>  */}
       
        <form className="mt-2 flex flex-col w-full h-full justify-between items-center  " onSubmit={submit}> 
        <div className='w-[80%] flex justify-end items-center '>
        {/* <Notification/> */}
        <input type="text"    className=' flex   bg-transparent text-right border-b-[1px] border-b-stone-950 w-[50%]' onChange={(e) => setnickName(e.target.value)}  value = {nickName}
         required />
        <label className='ml-4 flex justify-end font-semibold text-2xl text-right '>  :כינוי</label>

        </div>
        
       <div className='w-full'>
          <div className='flex items-center w-[90%]'style={{direction:"rtl"}}> 
              <input type="checkbox" className="w-5 h-5 border-2" onChange={()=>{setIsNote(!isNote) 
               }}  checked = {isNote}/>
              <img src={notification} className='w-[12%] mx-1'></img>
              <text className='font-bold text-[20px] text-[#737373] w-[40%] text-center'>תזכורת</text>
          </div>
          <div className='flex justify-center'>
                <text className='text-center  font-Inter
                  text-[12px]
                  font-normal
                  leading-4'> ?באיזה שעות תרצו תזכורות לתרגל</text>
                  {/* leading-4'> קביעת השעות בהן תקבלו תזכורות לתרגול</text> */}

          </div>

       </div>



        <div className='w-[80%] flex   justify-between'>
        <input type="time" min="13:00" max="00:00" placeholder={evening}  className=' flex  bg-transparent text-right  ' onChange={(e) => setEvening(e.target.value)}  value = {evening}
         required />
        {/* <label className='w-[40%] flex justify-end text-right  border-b-tahiti  border-b'>  :שעות ערב'</label> */}
        <img src={moon} className='w-[10%]'></img>
        
        
        <input type="time" min="00:00" max="13:00" placeholder={morning}  className=' flex  bg-transparent text-right  ' onChange={(e) => setMorning(e.target.value)}  value = {morning}
         required />
        {/* <label className=' w-[40%] flex justify-end text-right  border-b-tahiti  border-b'>  :שעות בוקר'</label> */}
        <img src={sun} className='w-[10%]'></img>
        </div>



        {/* <div className='w-[80%] flex justify-end  shadow-bermuda shadow-lg border-b '>
        
        </div> */}


        <div className='w-[80%]  flex justify-between my-2'>
        {/* <input type="radio"   className=''  value="guy" checked  /> */}
        <div className={gender=="male"?'w-[30%] rounded-[50%] pt-3 p-2 bg-[#824747]':' w-[30%] p-2 pt-3 bg-[#EADEDE]   rounded-[50%] opacity-50 }'}>
            <img src={man} className='w-full'  onClick={()=>setGender("male")}></img>
        </div>
        <div className={gender=="female"?'w-[30%] rounded-[50%] bg-[#824747] p-2 ':' w-[30%] p-2 bg-[#EADEDE]  rounded-[50%] opacity-50 }'}>
        <img src={woman} className='w-full' onClick={()=>setGender("female")}></img>
        </div>
        <label className=' flex justify-end text-right  font-semibold pt-4 pr-2'>  מין</label>
        </div>
       

        <div className='w-[90%] rounded-lg bg-slate-600 '>
            {isPlay?
              <video src="https://femalevideos.s3.eu-central-1.amazonaws.com/app+instructions.mp4" className='rounded-lg w-full '
            playing={true}
            controls={true}
            playsinline={true}
            autoPlay
            
        
        /> :
            <p className='w-full h-fit  flex items-center text-right p-2 text-white text-lg md:text-2xl' style={{direction:"rtl"}}> להתחלה, יש ללחוץ על מסך ההדרכות ולהתחיל את המסע משם. לפני שתתחילו לתרגל, עליכם להקשיב להדרכות כיצד לבצע את התרגולים. רק אחרי הקשבה להדרכה וביצוע התרגול הצמוד אליה, ניתן יהיה לבצע תרגולים באמצעות מסך התרגולים.</p>
            }
              </div>

        <div className='w-[90%] ' style={{direction:"rtl"}}>
          <text>סרטוני הדרכה</text>
          <div className='flex flex-row text-xs w-[80%] justify-between m-auto items-center'>
              <div className='w-full flex ' onClick={()=>{setisPlay(true)}}>
              {/* <div className='ml-2 text-sm'>התקנה</div> */}
              <div className='ml-2 text-sm'>תפעול האפליקציה</div>
              <img src={ Play_Circle}/>
              </div>
              {/* <div>הדרכות</div>
              <img src={ Play_Circle}/>

              <div >תרגולים</div>
              <img src={ Play_Circle}/> */}
             

          </div>
        </div>
        {/* <div className='w-full flex'>
        <input type="text" placeholder='שעות בוקר' className='w-[40%] flex text-right shadow-bermuda shadow-lg border-b   'onChange={(e) => setMorning(e.target.value)} 
        value = {morning}
        required    />
        <img src={sun} className='w-[10%]'></img>
        </div>
        <input type="text" placeholder='שעות ערב' className='w-full flex text-right shadow-bermuda shadow-lg border-b   'onChange={(e) => setEvening(e.target.value)} 
        value = {evening}
        required    /> */}
        <div className='w-[90%] flex justify-between  mb-2 pb-2'>
          {(nickName!=`${data?.data?.nickName}`||morning!=`${data?.data.morningPreferTime}`||evening!=`${data?.data.eveningPreferTime}`||gender!=`${data?.data?.gender}`||isNote!=data?.data?.isNote)?
            // <button className=' w-fit text-center p-2 rounded-lg bg-green-300 shadow-bermuda shadow-lg justify-self-center' >עדכן</button>:
            <button className=' w-fit text-center px-5 py-2 rounded-xl bg-[#394351] text-white font-bold text-[24px] font-Inter' >עדכן</button>:
            <button className=' w-fit text-center px-5 py-2 rounded-xl   bg-gray-300 text-warmGray-100 font-bold text-[24px] font-Inter  ' disabled >עדכן</button>}
            <Link to="/About" className="flex items-center">
              <p>אודות</p> <img src={infosvg} alt="infosvg" className="w-9 ml-2 "/></Link>
        </div>
        </form>
      </div>
    </div>
  )
}

export default Profile