import React, { useEffect, useState } from 'react'
import { useQuery } from "react-query";
import axios from 'axios'
import useAuth from '../../Hook/useAuth';
import  {useHistory , useLocation }  from "react-router-dom";
import Dashbord_Tutorilias_Bar from './Dashbord_Tutorilias_Bar';

const Dashbord_Tutorilias_info_chart = ({tab}) => {
    const { auth , logout , setAuth } = useAuth()
    const navigate = useHistory()
    const[info,SetInfo]=useState([])
   
   const { isLoading, isError, data, error, refetch } = useQuery([`exercise/action/${tab}`], () =>
    axios.get(`function_patient/exercise/action/${tab}` ,
    { 
    headers: { 'Content-Type': 'application/json'}
    } ,
    {'Access-Control-Allow-Credentials': true}) )
    useEffect(()=>{
     var info1 = []
          info1=data?.data?.map((x) => x);
      // data?.data.reverse()
      info1?.reverse()
      SetInfo(info1);
      
      
     },[data?.data])


    const arr=[]
    var max=0
    data?.data?.map((item)=>{
        // arr.push(item?.valueDay?.morning + item?.valueDay?.evening)
        if (max<item?.valueDay?.morning + item?.valueDay?.evening )
            max=(item?.valueDay?.morning + item?.valueDay?.evening)
    })
    max=Math.ceil((max+10)/10)
    // console.log(max)
  
  if (error) 
  {
    if(!auth)
    {
      navigate.replace('/Login')
    }
    return "An error has occurred: " + error.message;
  } 
  //  console.log(data?.data)
  //  console.log(info)
  
 
  return (
    <div className='w-full flex flex-row h-[40vh] mt-4 '>
        <div className=' w-[10%] mr-1 py-[4px] flex h-[95%] flex-col justify-between  border-r-2 text-start' style={{fontSize:"1.7vh",direction:"rtl"}} >
            <p className="flex " style={{fontSize:""}}>{(max)*10}<p className="px-[5px]" style={{fontSize:"1.7vh"}}> דק</p> </p>
            <p className="" style={{fontSize:""}}>{(max)*9}</p>
            <p className="" style={{fontSize:""}}>{(max)*8}</p>
            <p className="" style={{fontSize:""}}>{(max)*7}</p>
            <p className="" style={{fontSize:""}}>{(max)*6}</p>
            <p className="" style={{fontSize:""}}>{(max)*5}</p>
            <p className="" style={{fontSize:""}}>{(max)*4}</p>
            <p className="" style={{fontSize:""}}>{(max)*3}</p>
            <p className="" style={{fontSize:""}}>{(max)*2}</p>
            <p className="" style={{fontSize:""}}>{(max)*1}</p>
            <p className="" style={{fontSize:""}}>{0}</p>
            {/* <text>{Math.ceil((max+10)/10)*10}</text>
            <text>{Math.ceil((max+10)/10)*9}</text>
            <text>{Math.ceil((max+10)/10)*8}</text>
            <text>{Math.ceil((max+10)/10)*7}</text>
            <text>{Math.ceil((max+10)/10)*6}</text>
            <text>{Math.ceil((max+10)/10)*5}</text>
            <text>{Math.ceil((max+10)/10)*4}</text>
            <text>{Math.ceil((max+10)/10)*3}</text>
            <text>{Math.ceil((max+10)/10)*2}</text>
            <text>{Math.ceil((max+10)/10)*1}</text>
            <text>{Math.ceil((max+10)/10)*0}</text> */}
        </div>
        
        <div className={tab=="all"?' w-full h-full  overflow-x-auto relative mx-1 py-3    ':"w-full h-full  relative mx-1 py-3 "} style={{direction:"rtl"}}  >
        <div className={(tab=="all")?'flex  content-end mx-1 px-2  h-[87%] border-b-2 absolute border-black':'flex  flex-row justify-between content-end  w-full h-[95%] border-b-2 border-black'} style={{ backgroundSize: "100% 10%", backgroundImage: "linear-gradient(to bottom,black 3%,transparent 7%,transparent 100%)"}} > 
            
                {info?.map((item,index)=> {
                  const [year, month, day] = item?.date.split('-');
                  
                  return(
                <div className='flex justify-center h-full relative   '>
                <Dashbord_Tutorilias_Bar morning={Math.round((item?.valueDay?.morning/(max*10))*100)} evening={Math.round((item?.valueDay?.evening/(max*10))*100)} ></Dashbord_Tutorilias_Bar>
                <div className='flex absolute -bottom-2 justify-center '>
                     <div className='w-[1vw] h-[1vw] bg-slate-500   rounded-full '></div>
                     {index==0|| index==info.length-1?<p className='flex absolute -bottom-4 -left-3' style={{fontSize:"3vw"}}>{day+"."+month} </p>:"" }
                     </div>
                </div>)})}
                </div>
            
            <div>
            {/* {data?.data?.map((item)=>
            {<text>{item?.date} </text>})}
             */}
            </div>
            </div>
    </div>
  )
}

export default Dashbord_Tutorilias_info_chart