import React, { useEffect, useState } from 'react'

const ScaleH_Daily_summary = ({title,high, low}) => {
 const delta=(high-low)
 const min=low
 const max=high
 if (delta<0)
 {
    high=min 
    low=max}
 
// const [delta,setdelta]=useState(0)
// useEffect(()=>{
//     setdelta(high)
// },[])

    return (
    <div className='flex w-[20vw] flex-col  '>
        <div className=' flex justify-start'>
            <text className='font-Hebbo font-semibold' style={{fontSize:"6vw"}}>{title}</text>
        </div >
        <div className="flex  justify-center flex-row">
            <div className="w-[5vw]   bg-gray-300 relative rounded-lg">
                {
                    // console.log(`w-full h-[${delta*9}%] bg-tahiti absolute top-[14%] `)


                }
                <div className={`w-full  ${delta<0?"bg-red-600":"bg-green-600"} absolute `} style={{height:`${Math.abs(delta*9)+2}%`,top:`${(10-high)*9+4}%`}}></div>
            </div>
            <div className=' flex flex-col mx-2 '>
            <p className='font-Hebbo' style={{fontSize:"4vw"}}>10</p>
            <p className='font-Hebbo' style={{fontSize:"4vw"}}>9</p>
            <p className='font-Hebbo' style={{fontSize:"4vw"}}>8</p>
            <p className='font-Hebbo' style={{fontSize:"4vw"}}>7</p>
            <p className='font-Hebbo' style={{fontSize:"4vw"}}>6</p>
            <p className='font-Hebbo' style={{fontSize:"4vw"}}>5</p>
            <p className='font-Hebbo' style={{fontSize:"4vw"}}>4</p>
            <p className='font-Hebbo' style={{fontSize:"4vw"}}>3</p>
            <p className='font-Hebbo' style={{fontSize:"4vw"}}>2</p>
            <p className='font-Hebbo' style={{fontSize:"4vw"}}>1</p>
            <p className='font-Hebbo' style={{fontSize:"4vw"}}>0</p>
            </div>
            {/* <div className='flex justify-center flex-row h-[10vw]'>
                <div className=' bg-metal w-[4vw] rounded-t-xl'>
                     
                </div>
                <div className=' flex  w-5'>
                    <p className='font-Hebbo' style={{fontSize:"10vw"}}>10</p>
                </div>
            </div>
            <div className='flex justify-center flex-row h-[10vw]'>
                <div className=' bg-tahiti w-[4vw] '>
                     
                </div>
                <div className=' flex items-end w-5'>
                    <p className='font-Hebbo m-[-2px]' style={{fontSize:"10vw"}}>9</p>
                </div>
            </div>
            <div className='flex justify-center flex-row h-[10vw]'>
                <div className=' bg-tahiti w-[4vw] '>
                     
                </div>
                <div className=' flex items-center w-5'>
                    <p className='font-Hebbo' style={{fontSize:"10vw"}}>8</p>
                </div>
            </div>
            <div className='flex justify-center flex-row h-[6vw]'>
                <div className=' bg-tahiti w-[4vw] '>
                     
                </div>
                <div className=' flex items-center w-5'>
                    <p className='font-Hebbo' style={{fontSize:"6vw"}}>7</p>
                </div>
            </div>
            <div className='flex justify-center flex-row h-[6vw]'>
                <div className=' bg-metal w-[4vw] rounded-b-xl '>
                     
                </div>
                <div className=' flex items-center w-5'>
                    <p className='font-Hebbo p-[-2px]' style={{fontSize:"6vw"}}>6</p>
                </div>
            </div> */}
        </div>

    </div>
  )
}

export default ScaleH_Daily_summary