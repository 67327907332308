import React from 'react'
import {ReactComponent as Openscreenlogo} from "../Images/Icons/Logo_Header.svg"
import Notification from "./UI/Notification"
import { useLocation,useHistory } from 'react-router-dom/cjs/react-router-dom';
import useAuth from '../Hook/useAuth';

const Header = () => {
  const { auth  } = useAuth()
  const location = useLocation();
 
  // בדיקה אם נמצאים בדף "/Messages"
  const isMessagesPage = location.pathname === "/Messages";
  return (
    // <div className='w-full flex flex-row-reverse  ' style={{backgroundImage:"linear-gradient(90deg, #E72144 0%, #76549E 99.94%)",opacity: 0.05}}>            
    <div className='w-full h-[10%] flex flex-row-reverse  justify-between px-2 items-center' style={{background: "linear-gradient(to right,rgba(231, 33, 68, 0.05), rgba(118, 84, 158, 0.05))"}}>            
            {/* <Openscreenlogo className=' w-[43px] sm:w-[80px] md:w-[200px] absolute top-3 right-3' /> */}
            {/* <Openscreenlogo className=' w-[8vh] sm:w-[80px] md:w-[100px] p-2' /> */}
            <Openscreenlogo className=' w-[8vh] p-2 ' />
            {auth&&
            ((!isMessagesPage&&<Notification></Notification>)||
            (isMessagesPage&&
   



    <button
      onClick={() => window.history.back()}
      style={{
        display: "flex",
        alignItems: "center",
        padding: "10px 15px",
        backgroundColor: "#2196F3",
        color: "white",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
      }}
    >
      <span style={{ marginRight: "8px" }}>⬅</span> {/* אייקון החץ */}
      חזרה
    </button>))}
        </div>
  )
}

export default Header