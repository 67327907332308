import React, { useState } from 'react'
import {ReactComponent as Luck} from "../Images/Stages_lock/icon_lock.svg"
import Nav from '../Components/Nav'
import Header from '../Components/Header'
import { useEffect } from 'react'

import barProgBar_1 from "../Images/Prog-bar/ProgBar_1.svg"
import barProgBar_2 from "../Images/Prog-bar/ProgBar_2.svg"
import barProgBar_3 from "../Images/Prog-bar/ProgBar_3.svg"
import barProgBar_4 from "../Images/Prog-bar/ProgBar_4.svg"
import barProgBar_5 from "../Images/Prog-bar/ProgBar_5.svg"
import ProgBar_start from "../Images/Prog-bar/ProgBar_start.svg"
import map from "../Images/map.svg"
import Map from '../Components/Map'
import Map2 from '../Components/Map2'
import Map3 from '../Components/Map3'

import axios from 'axios'
import useAuth from '../Hook/useAuth'
import  {useHistory , useLocation }  from "react-router-dom";
import { Redirect } from 'react-router-dom/cjs/react-router-dom'
// import Map4new from '../Components/Map4new'



const Map_Practice = () => {
    

    // const { auth , logout , setAuth } = useAuth()
    // // const navigate = useHistory()
 
    // // if(auth)
    // // {
        
    // // }
    // // else{
    // //      navigate.replace('/Login')
    // // }
    // if(!auth)
    //     {
    //       <Redirect  to="'/Login"/>
    //     }


    const [myPosition, SetMyPosition ]  = useState({})
    
    // const { auth , logout , setAuth } = useAuth()
    // const SITE_URL = "function_patient/showMapLevel"
    // const navigate = useHistory()
    // const [levelMap,SetLevelMap] = useState([{}])
    // const [techMap , SetTechMap ]  = useState([{}])
    // const [data , Setdata ]  = useState({})
    // const [myPosition , SetMyPosition ]  = useState({})
  

    // useEffect ( ( ) => 
    // { 
    //   console.log(levelMap)
    // } , [levelMap])
    // useEffect ( ( ) => 
    // { 
    //   console.log(myPosition)
    // } , [myPosition])

    // useEffect ( ( ) => 
    // { 
    //   console.log(techMap)
    // } , [techMap])

    // useEffect ( ( ) => 
    // { 
    //   console.log(data)
    // } , [data])

    // const MapUsers = async() => 
    // {
    //     if(auth)
    //     {

    //     await axios.get("function_patient/showMapLevel" ,
    //         { 
    //         headers: { 'Content-Type': 'application/json'}
    //         } ,
    //         {'Access-Control-Allow-Credentials': true}).then(res => 
    //         {
    //         if(res?.status === 200)
    //         {
    //             SetMyPosition(res.data.myPosition.levelId)   
    //             // SetLevelMap(res.data.infoLevel.levelMap )
    //             // SetTechMap(res.data.infoLevel.techniqueMap)
    //             // Setdata(res.data)
             
    //         }
    //         else{
    //             navigate.replace('/login')
    //             // navigate('/' , {replace: true})
    //         }
    //         })
    //     .catch(err => {
    //         console.log("err")
    //         logoutFun()
    //     })
    //     }
    //     else{
    //         logoutFun()
    //     }
        
    // }

    // const logoutFun = async () => {
    //     setAuth(null)
    //     //logout()
    //     navigate.replace('/Login')
    //   }
    // useEffect ( ( ) => 
    // { 
    //   MapUsers()
    // } , [])


  return (
        <div className='h-[80%]   'style={{background: "linear-gradient(0deg, #F0F0F0 0.06%, #F4F4F4 82.66%, #F6F6F6 100%)"}}>
            <div className='contents  ' >
                <div class=" h-full flex flex-row items-center">
                    <div className='ml-4  pl-10 p-4  w-5/6 h-full '>
                    {/* <img src={map} alt="s" className='' /> */}
                    {/* <Map4new myPosition={myPosition} SetMyPosition ={SetMyPosition}></Map4new> */}
                    <Map3 myPosition={myPosition} SetMyPosition ={SetMyPosition} />
                    </div>
                    <div className='p-2 flex items-end justify-end  w-1/6 h-full' >
                        <img src={myPosition==5?barProgBar_5:myPosition==4?barProgBar_4:myPosition==3?barProgBar_3:myPosition==2?barProgBar_2:barProgBar_1} 
                            alt="s"/>
                         {/* {()=>{switch (i) {
                            case "s":
                                <img src={barProgBar_5} alt="s"/> 
                                break;
                         
                            case "2":
                                <img src={barProgBar_2} alt="s"/>
                                break;
                                }}} */}
                   </div>
                    
                </div>
            </div>
            {/* <Nav num="5"/> */}
        </div>
  )
}

export default Map_Practice