import axios from 'axios';
import React, { useEffect, useState } from 'react'

const InfoPop = ({main_title ,setShow}) => {
    const [data, setData] = useState(null);
    const fetchData = async () => {
        try {
          const response = await axios.get("function_patient/info_carousel ", {
            headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true }
          });
          if (response.status === 200) {
            // console.log("Fetched Data:", response.data); // הדפסת המידע
            setData( response.data);
          }
        } catch (error) {
          console.error("Error fetching data:", error); // הדפסת השגיאה
        }
      };

      useEffect(()=>{ fetchData()},[])  
  return (
    <div id="popup" className='h-full w-full  fixed top-0  left-0  bg-[rgba(49,49,49,0.8)] flex justify-center items-center flex-col'>
    <div className='bg-white w-[70%] rounded-xl '>
    {/* <div id="overlay" className='rounded-xl w-[100%] border border-b-transparent p-3 relative flex flex-col items-center  ' style={{background:"linear-gradient(90deg, rgba(231, 33, 68, 0.05), rgba(118, 84, 158, 0.05))"}}> */}
    <div id="overlay" className='rounded-xl w-[100%] border border-b-transparent p-3 relative flex flex-col items-center bg-red-50 '>
    
       {/* <img src={bubble} alt="" className="h-[30vh]" /> */}
     
        <div className='w-[100%] p-2  ' > 
        <button className="top-2 right-4 bg-none absolute border-0 text-xl text-[#d9534f cursor-pointer]" onClick={()=>{
          setShow(false)
        }}>×</button>
          <h3
          className='font-bold text-lg underline text-[#d9534f] mb-2 text-center'

          >{main_title}</h3>
          <p className=' w-full text-base text-[#333] whitespace-pre-line ' style={{direction: "rtl"}} > 
          {
        data?.[main_title]

//   
          }</p>
        </div>
       
        {/* <div className='w-[40px] h-[40px] bg-white absolute top-[92%]  border-transparent       rotate-45 ;'></div> */}
        
        {/* <div className=' absolute top-[98%] border-r-transparent border-r-[30px]  border-l-[30px] border-t-[22px]  border-l-transparent  border-t-red-50' ></div> */}

    </div>
    </div>
       
   
</div>
  )
}

export default InfoPop