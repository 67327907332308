import {React,useState,useEffect} from 'react'
import Bar_Gauge from './Bar_Gauge'
import { useQuery } from "react-query";
import axios from 'axios'
import useAuth from '../../Hook/useAuth';
import  {useHistory , useLocation }  from "react-router-dom";



const Gauge_Chart = ({Tub,days,daystate}) => {
    
    
    const [datachart ,setDatachart] = useState([{}])
    const { auth , logout , setAuth } = useAuth()
    const navigate = useHistory()
    
    
    const { isLoading, isError, data, error, refetch } = useQuery([`measure/${days}`], () =>
    axios.get(`function_patient/exercise/measure/${days}` ,
    { 
    headers: { 'Content-Type': 'application/json'}
    } ,
    {'Access-Control-Allow-Credentials': true})
  
    // {
    //     onSuccess: (res) => {
    //       const result = {
    //         data: res.data,
    //       };  
    //       setDatachart(result.data)
    //     },
    //     onError: (err) => {
    //     },
    // }

  
    )
    useEffect(()=>{
      
        setDatachart(data?.data)
        },[data?.data])
  if (error) 
  {
    if(!auth)
    {
      navigate.replace('/Login')
    }
    return "An error has occurred: " + error.message;
  } 
//    console.log(data?.data)



    
  return (
    <div className='flex flex-row w-full h-[40vh] px-2 mb-2 '>
        <div className='flex flex-col h-full  justify-between mr-1' >
            <p className="" style={{fontSize:""}}>10</p>
            <p className="" style={{fontSize:""}}>9</p>
            <p className="" style={{fontSize:""}}>8</p>
            <p className="" style={{fontSize:""}}>7</p>
            <p className="" style={{fontSize:""}}>6</p>
            <p className="" style={{fontSize:""}}>5</p>
            <p className="" style={{fontSize:""}}>4</p>
            <p className="" style={{fontSize:""}}>3</p>
            <p className="" style={{fontSize:""}}>2</p>
            <p className="" style={{fontSize:""}}>1</p>
            <p className="" style={{fontSize:""}}>0</p>
        </div>
        
        
            <div className='flex  w-full h-full py-2 '>
            <div className='flex flex-row w-full h-[100%] justify-between  border-y-2 border-t-[#EFEEEE] border-b-black  ' style={{backgroundSize: "100% 10%", backgroundImage: "linear-gradient(to bottom,#EFEEEE 3%,transparent 7%,transparent 100%)"}}>
                {datachart?.map((day,index)=>
                {
                     const [year, month, day1] = `${day?.date}`.split('-');
                  
                    return(
                        //כאן צריך לשנות את הגודל
                <div className=' w-full h-[100%] flex flex-col items-center'>
                    <div className='bg-[#EFEEEE] w-[50%] relative h-full  flex justify-center '>
                    
                        {(daystate=="Morning")&&(Tub==1)&&<Bar_Gauge q1={day?.sessionMorning?.fatigueQ1} q2={day?.sessionMorning?.fatigueQ2}/>}
                        {(daystate=="Morning")&&(Tub==2)&&<Bar_Gauge q1={day?.sessionMorning?.sudsQ1} q2={day?.sessionMorning?.sudsQ2}/>}
                        {(daystate=="Morning")&&(Tub==3)&&<Bar_Gauge q1={day?.sessionMorning?.vasQ1} q2={day?.sessionMorning?.vasQ2}/>}
                    {(daystate=="Morning")&&(Tub==4)&&<Bar_Gauge q1={day?.sessionMorning?.well_beingQ2} q2={day?.sessionMorning?.well_beingQ1}/>}
                    

                    {(daystate=="Evening")&&(Tub==1)&&<Bar_Gauge q1={day?.sessionEvening?.fatigueQ1} q2={day?.sessionEvening?.fatigueQ2}/>}
                    {(daystate=="Evening")&&(Tub==2)&&<Bar_Gauge q1={day?.sessionEvening?.sudsQ1} q2={day?.sessionEvening?.sudsQ2}/>}
                    {(daystate=="Evening")&&(Tub==3)&&<Bar_Gauge q1={day?.sessionEvening?.vasQ1} q2={day?.sessionEvening?.vasQ2}/>}
                    {(daystate=="Evening")&&(Tub==4)&&<Bar_Gauge q1={day?.sessionEvening?.well_beingQ2} q2={day?.sessionEvening?.well_beingQ1}/>}
                    
                    <div className='flex absolute -bottom-2 justify-center'>
                     <div className='w-[1vw] h-[1vw] bg-slate-500   rounded-full '></div>
                     {index==0|| index==datachart.length-1?<p className='flex absolute  ' style={{fontSize:"3vw"}}>{day1+"."+month} </p>:"" }
                     </div>
                </div>
                   
                    {/* <text className=''>{day?.date}</text> */}
                    {/* {console.log(new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(day?.date))} */}
                </div>  
                )})}  

                {/* <div className=''>
                    <div className='bg-slate-500 w-[4vw] relative h-full '>
                    <div className="bg-orange-300 w-full  absolute top-0" style={{height:`${Tub1*9.9+1}%`}} ></div>
                    </div>
                    <text>5.1</text>
                </div>    
                <div className=''>
                    <div className='bg-slate-500 w-[4vw] relative h-full '>
                    <div className="bg-orange-300 w-full  absolute top-[0%]" style={{height:`${10*9.9+1}%`}} ></div>
                    </div>
                    <text>5.1</text>
                </div>    
                <div className=''>
                    <div className='bg-slate-500 w-[4vw] relative h-full '>
                    <div className="bg-orange-300 w-full  absolute top-0" style={{height:`${8*9.9+1}%`}} ></div>
                    </div>
                    <text>5.1</text>
                </div>    
                <div className=''>
                    <div className='bg-slate-500 w-[4vw] relative h-full '>
                    <div className="bg-orange-300 w-full  absolute top-2" style={{height:`${Tub1*9.5+2}%`}} ></div>
                    </div>
                    <text>5.1</text>
                </div>     */}
             
            
            </div>    
            {/* <div className='flex flex-row justify-between'> */}
            {/* <text>10.4</text>
            <text>9</text>
            <text>8</text>
            <text>7</text>
            <text>6</text>
            <text>5</text>
            <text>10</text> */}
            {/* </div>     */}
        </div>
    </div>
  )
}

export default Gauge_Chart