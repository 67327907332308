import {createContext , useEffect, useState} from "react"
import Cookies from "universal-cookie"
import axios from "axios"

const AuthContext = createContext({})

export const AuthProvider = ({ children }) =>
{
    const cookies = new Cookies();
    const load = cookies.get('bezkoder-jwt-refresh'); 
    const [auth , setAuth]  = useState(load ? cookies.get('bezkoder-jwt-refresh') : null)



    const login = (loginAuth) => 
    {
        // cookies.set('bezkoder-jwt-refresh' , JSON.stringify(loginAuth['refersh_token']) ,  { path: '/'  , maxAge: 31536000})
        // axios.defaults.headers.common['Authorization'] = `Bearer ${loginAuth['access_token']}`;
      
    }
    const logout = async() => 
    {

        // setSite_id(null)
        // cookies.remove('save_site')

        const refershToken = cookies.get('bezkoder-jwt-refresh')
        console.log(refershToken)
        const {data} = await axios.post('signout' , {"token":refershToken});
        cookies.remove('bezkoder-jwt-refresh')
        axios.defaults.headers.common['Authorization'] = null;
        console.log(data?.message)
        cookies.remove('wonderPush_token')
    }


    // const checkAuth = async () => {
    //     if (!accessToken) return;
    
    //     try {
    //       const response = await fetch("https://example.com/api/validate-token", {
    //         method: "POST",
    //         headers: { Authorization: `Bearer ${accessToken}` },
    //       });
    
    //       if (!response.ok) {
    //         // נסה לרענן את הטוקן
    //         const refreshResponse = await fetch("https://example.com/api/refresh-token", {
    //           method: "POST",
    //           credentials: "include", // שולח את ה-Refresh Token
    //         });
    
    //         if (refreshResponse.ok) {
    //           const data = await refreshResponse.json();
    //           login(data.accessToken); // שמור את הטוקן החדש
    //         } else {
    //           logout(); // אם רענון הטוקן נכשל, בצע יציאה
    //         }
    //       }
    //     } catch (error) {
    //       console.error("Error validating token:", error);
    //       logout();
    //     }
    //   };
    
    //   // אפקט שמוודא רענון הטוקן
    //   useEffect(() => {
    //     checkAuth();
    //   }, [accessToken]);


    return (
        <AuthContext.Provider value={{auth , setAuth , login , logout}}>
            {children} 
        </AuthContext.Provider>
    )
}
export default AuthContext;



// var today = new Date();
            // var nextSession = new Date() 
            // nextSession.setDate(today.getDate() + 100 );
            // cookies.set('jwt_authorization' , JSON.stringify(auth) ,  { path: '/'  , maxAge: 31536000} , 
            // {
            //     expires: nextSession
            // }
            // )